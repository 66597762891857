import * as queries from '@data/queries'
import { getClient } from '@lib/sanity.server'

/*
██████╗  █████╗  ██████╗ ███████╗
██╔══██╗██╔══██╗██╔════╝ ██╔════╝
██████╔╝███████║██║  ███╗█████╗  
██╔═══╝ ██╔══██║██║   ██║██╔══╝  
██║     ██║  ██║╚██████╔╝███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚══════╝
*/
// Fetch all dynamic pages
export async function getAllPages(type) {
  // TODO exclude drafts from built pages before going live
  const query = `*[_type == "${type}" && !(slug.current in [${queries.homeSlug}, ${queries.workSlug}, ${queries.blogSlug}, ${queries.careersSlug}, ${queries.contactSlug}, ${queries.studioSlug}, ${queries.newsletterSlug}])]{ "slug": slug.current }`
  const data = await getClient().fetch(query)
  return data
}

// Fetch a specific dynamic page with our global data
export async function getPage(slug, preview) {
  const slugs = [`/${slug}`, slug, `/${slug}/`]

  const query = `
    {
      "page": *[_type == "page" && slug.current in ${JSON.stringify(
        slugs
      )}] | order(_updatedAt desc)[0]{
        title,
        altPageTitle,
        pageIntro,
        landingPage,
        hideFooter,
        deIndex,
        mainImage,
        "slug": slug { current },
        seoContent,
        pageBuilder[]{
          ${queries.builderQueries}
        }
      }
    }
    `

  const data = await getClient(preview).fetch(query)

  return data
}

/*
  ______   _______   ________  ______   ______   __        ________ 
 /      \ /       \ /        |/      | /      \ /  |      /        |
/$$$$$$  |$$$$$$$  |$$$$$$$$/ $$$$$$/ /$$$$$$  |$$ |      $$$$$$$$/ 
$$ |__$$ |$$ |__$$ |   $$ |     $$ |  $$ |  $$/ $$ |      $$ |__    
$$    $$ |$$    $$<    $$ |     $$ |  $$ |      $$ |      $$    |   
$$$$$$$$ |$$$$$$$  |   $$ |     $$ |  $$ |   __ $$ |      $$$$$/    
$$ |  $$ |$$ |  $$ |   $$ |    _$$ |_ $$ \__/  |$$ |_____ $$ |_____ 
$$ |  $$ |$$ |  $$ |   $$ |   / $$   |$$    $$/ $$       |$$       |
$$/   $$/ $$/   $$/    $$/    $$$$$$/  $$$$$$/  $$$$$$$$/ $$$$$$$$/ 
*/

// Fetch all blog posts
export async function getAllArticles(type) {
  const data = await getClient().fetch(
    `*[_type == "${type}" && defined(slug.current) && !(_id in path("drafts.**"))][]{ 
      "slug": slug.current,
      title,
      articleContent,
      excerpt,
      publishedAt
    }`
  )
  return data
}


// Fetch all blog posts
export async function getAllArticlesForSEO(type) {
  const data = await getClient().fetch(
    `*[_type == "${type}" && defined(slug.current) && !(_id in path("drafts.**"))][]{ 
      "slug": slug.current,
      title,
    }`
  )
  return data
}

// Fetch a specific article page with our global data
export async function getArticle(slug, preview) {
  const slugs = [
    `/${slug}`,
    slug,
    `/${slug}/`,
    `/blog/${slug}`,
    `/blog/${slug}/`,
    `/news/${slug}`,
    `/news/${slug}/`,
  ]

  const query = `
    {
      "article": *[_type == "article" && slug.current in ${JSON.stringify(
        slugs
      )}] | order(_updatedAt desc)[0]{
        ${queries.articleQuery}
      }
    }
    `

  const data = await getClient(preview).fetch(query)

  return data
}

// get only feed data for articles
export async function getArticleFeedData(slug) {
  const slugs = [
    `/${slug}`,
    slug,
    `/${slug}/`,
    `/blog/${slug}`,
    `/blog/${slug}/`,
    `/news/${slug}`,
    `/news/${slug}/`,
  ]

  const query = `
    {
      "article": *[_type == "article" && slug.current in ${JSON.stringify(
        slugs
      )}] | order(_updatedAt desc)[0]{
        "slug": slug.current,
        title,
        articleContent,
        excerpt,
        publishedAt
      }
    }
    `
  const data = await getClient().fetch(query)

  return data
}


/*
██╗    ██╗ ██████╗ ██████╗ ██╗  ██╗
██║    ██║██╔═══██╗██╔══██╗██║ ██╔╝
██║ █╗ ██║██║   ██║██████╔╝█████╔╝ 
██║███╗██║██║   ██║██╔══██╗██╔═██╗ 
╚███╔███╔╝╚██████╔╝██║  ██║██║  ██╗
 ╚══╝╚══╝  ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝
*/

// get all work / project items. This will generate pages at build time
export async function getAllWorkitems(type) {
  const data = await getClient().fetch(
    `*[_type == "${type}" && defined(slug.current) && !(_id in path("drafts.**"))][]{ "slug": slug.current }`
  )
  return data
}

// get info on the actual project, pull the data from the passed current slug
export async function getProject(slug, preview) {
  const slugs = [
    `/${slug}`,
    slug,
    `/${slug}/`,
    `/work/${slug}`,
    `/work/${slug}/`,
    `/project/${slug}`,
    `/project/${slug}/`,
  ]

  const query = `{
    "project": *[_type == "project" && slug.current in ${JSON.stringify(slugs)}][0]{
      ${queries.projectQuery}
    },
  }`

  const data = await getClient(preview).fetch(query)

  return data
}

/*
 ██████  █████  ██████  ███████ ███████ ██████  ███████ 
██      ██   ██ ██   ██ ██      ██      ██   ██ ██      
██      ███████ ██████  █████   █████   ██████  ███████ 
██      ██   ██ ██   ██ ██      ██      ██   ██      ██ 
 ██████ ██   ██ ██   ██ ███████ ███████ ██   ██ ███████ 
*/
// get all the job listings that are not drafts. This will generate pages at build time
export async function getAllJobs(type) {
  const data = await getClient().fetch(
    `*[_type == "${type}" && defined(slug.current) && !(_id in path("drafts.**"))][]{ "slug": slug.current }`
  )
  return data
}

// get info on the actual job, pull the data from the passed current slug
export async function getJob(slug, preview) {
  const slugs = [
    `/${slug}`,
    slug,
    `/${slug}/`,
    `/careers/${slug}`,
    `/careers/${slug}/`,
    `/jobs/${slug}`,
    `/jobs/${slug}/`,
  ]

  const query = `{
  "job": *[_type == "job" && slug.current in ${JSON.stringify(slugs)}][0]{
    ...
  },
}`
  const data = await getClient(preview).fetch(query)
  return data
}

/*
███    ██ ███████ ██     ██ ███████ ██      ███████ ████████ ████████ ███████ ██████  ███████ 
████   ██ ██      ██     ██ ██      ██      ██         ██       ██    ██      ██   ██ ██      
██ ██  ██ █████   ██  █  ██ ███████ ██      █████      ██       ██    █████   ██████  ███████ 
██  ██ ██ ██      ██ ███ ██      ██ ██      ██         ██       ██    ██      ██   ██      ██ 
██   ████ ███████  ███ ███  ███████ ███████ ███████    ██       ██    ███████ ██   ██ ███████ 
*/
// get all the newsletters that are not drafts. This will generate pages at build time
export async function getAllNewsletters(type) {
  const data = await getClient().fetch(
    `*[_type == "${type}" && defined(slug.current) && !(_id in path("drafts.**"))][]{ 
      "slug": slug.current,
      Date,
      title,
      subtitle
    }`
  )
  return data
}

// get the newsletter itself, pull the data from the passed current slug
export async function getNewsletter(slug, preview) {
  const slugs = [`/${slug}`, slug, `/newsletters/${slug}/`]

  const query = `
  {
    "newsletter": *[_type == "newsletter" && slug.current in ${JSON.stringify(
      slugs
    )}] | order(_updatedAt desc)[0]{
      ${queries.newsletter}
    }
  }`
  const data = await getClient(preview).fetch(query)
  return data
}

/*
 ██████  ██       ██████  ██████   █████  ██      
██       ██      ██    ██ ██   ██ ██   ██ ██      
██   ███ ██      ██    ██ ██████  ███████ ██      
██    ██ ██      ██    ██ ██   ██ ██   ██ ██      
 ██████  ███████  ██████  ██████  ██   ██ ███████
*/
// Fetch a static page with our global data
export async function getStaticPage(pageData, preview) {
  const query = `
  {
    ${pageData},
  }
  `

  const data = await getClient(preview).fetch(query)

  return data
}

// Fetch a static page with our global data
export async function getSiteData(preview) {
  const query = `
  {
    ${queries.site},
  }
  `

  const data = await getClient(preview).fetch(query)

  return data
}

export { queries }
