import '@styles/fonts.css'
import '@styles/main.css'

import { getSiteData } from '@data/index'
import { AppContext } from '@hooks/useAppContext'
import { GTM_ID, pageview } from '@lib/gtm'
import App from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'
import { CookiesProvider } from 'react-cookie'

function MyApp({ Component, pageProps, siteData }) {
  const globals = siteData

  const router = useRouter()
  useEffect(() => {
    router.events.on('routeChangeComplete', pageview)
    return () => {
      router.events.off('routeChangeComplete', pageview)
    }
  }, [router.events])

  useEffect(()=>{
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          // unregister service worker
          console.log('serviceWorker unregistered');
          registration.unregister();
        }
      });
    }
  },[])

  return (
    <AppContext.Provider value={{ globals }}>
      <CookiesProvider>
        <Script
          id="gtag-base"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
          }}
        />
        <Component {...pageProps} key={router.route} />
      </CookiesProvider>
    </AppContext.Provider>
  )
}

export default MyApp

MyApp.getInitialProps = async (appContext) => {
  App.getInitialProps(appContext)
  const siteData = await getSiteData()
  return {
    siteData: siteData,
  }
}
