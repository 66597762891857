import { config } from '@lib/config'
import { createClient } from 'next-sanity'

// Set up the client for fetching data in the getProps page functions
export const sanityClient = createClient(config)

// Set up a preview client with serverless authentication for drafts
export const previewClient = createClient({
  ...config,
  // useCdn: false,
  watchMode: process.env.APP_ENV === 'staging',
})

// Helper function for easily switching between normal client and preview client
export const getClient = (usePreview) =>
  usePreview ? previewClient : sanityClient
