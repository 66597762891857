// Define top level pages
export const homeID = `*[_type=="pageSettings"][0].homePage->_id`
export const workID = `*[_type=="pageSettings"][0].workPage->_id`
export const blogID = `*[_type=="pageSettings"][0].blogPage->_id`
export const studioID = `*[_type=="pageSettings"][0].studioPage->_id`
export const contactID = `*[_type=="pageSettings"][0].contactPage->_id`
export const careersID = `*[_type=="pageSettings"][0].careersPage->_id`
export const newsletterID = `*[_type=="pageSettings"][0].newsletterPage->_id`

export const homeSlug = `*[_type=="pageSettings"][0].homePage->slug.current`
export const workSlug = `*[_type=="pageSettings"][0].workPage->slug.current`
export const blogSlug = `*[_type=="pageSettings"][0].blogPage->slug.current`
export const studioSlug = `*[_type=="pageSettings"][0].studioPage->slug.current`
export const contactSlug = `*[_type=="pageSettings"][0].contactPage->slug.current`
export const careersSlug = `*[_type=="pageSettings"][0].careersPage->slug.current`
export const newsletterSlug = `*[_type=="pageSettings"][0].newsletterPage->slug.current`

// Fragment: Image Meta
export const imageMeta = `
alt,
asset,
crop,
customRatio,
hotspot
`

// Fragment: Text Meta
export const textMeta = `
...,
"bg": ^.mainImage.asset->metadata.palette.vibrant.background,
markDefs[]{
  ...,
  _type == "internalLink" => {
    ...,
    "slug": *[_id == ^.reference._ref][0].slug.current,
    "internalUrl": "/" + *[_id == ^.reference._ref][0].slug.current,
    "type": *[_id == ^.reference._ref][0]._type
  },
  _type == "anchor" => {
    ...,
    anchor,
  },
},
`

// Fragment: Related Article
export const relatedArticles = `
"title": *[_id == ^._ref][0].title,
"slug": *[_id == ^._ref][0].slug.current,
"publishedAt": *[_id == ^._ref][0].publishedAt,
"author": *[_id == ^._ref][0].author,  
"image": *[_id == ^._ref][0].mainImage,
"times": {
  "numberOfCharacters": length(pt::text(*[_id == ^._ref][0].articleContent)),
  "estimatedWordCount": round(length(pt::text(*[_id == ^._ref][0].articleContent)) / 5),
  "estimatedReadingTime": round(length(pt::text(*[_id == ^._ref][0].articleContent)) / 5 / 180)
}
`

// Fragment: CTA
export const cta = `
"type": cta.ctaType,
"theme": cta.ctaTheme,
"isEnabled": cta.ctaEnabled,
"link": {
  "internalUrl": {
    "path": *[_id == ^.cta.ctaLink.internalLink._ref][0]._type,
    "url": "/" + *[_id == ^.cta.ctaLink.internalLink._ref][0].slug.current
  },
  "externalUrl": {
    "url": cta.ctaLink.externalUrl
  },
},
"text": cta.ctaText
`

/*
███╗   ███╗██╗███╗   ██╗██╗███╗   ███╗ █████╗ ██╗     
████╗ ████║██║████╗  ██║██║████╗ ████║██╔══██╗██║     
██╔████╔██║██║██╔██╗ ██║██║██╔████╔██║███████║██║     
██║╚██╔╝██║██║██║╚██╗██║██║██║╚██╔╝██║██╔══██║██║     
██║ ╚═╝ ██║██║██║ ╚████║██║██║ ╚═╝ ██║██║  ██║███████╗
╚═╝     ╚═╝╚═╝╚═╝  ╚═══╝╚═╝╚═╝     ╚═╝╚═╝  ╚═╝╚══════╝
*/
// MINIMAL: Article
export const articleQueryMinimal = `
title,
"slug": slug.current,
publishedAt,
"image": mainImage,
"times": {
  "numberOfCharacters": length(pt::text(articleContent)),
  "estimatedWordCount": round(length(pt::text(articleContent)) / 5),
  "estimatedReadingTime": round(length(pt::text(articleContent)) / 5 / 180)
}
`

// MINIMAL: Project
export const projectQueryMinimal = `
"title": projectClient,
"slug": "/work/" + slug.current,
"desc": projectShortDescription,
"image": projectThumbnail,
`

// MINIMAL: Newsletter
export const newsletterQueryMinimal = `
"title": "#" + episode + " ― " + title,
"slug": "/newsletters/" + slug.current,
"date": Date,
"thumbnail": thumbnailImage,
"introduction": introduction,
"excerpt": excerpt
`

/*
██████╗ ███████╗ ██████╗███████╗███╗   ██╗████████╗
██╔══██╗██╔════╝██╔════╝██╔════╝████╗  ██║╚══██╔══╝
██████╔╝█████╗  ██║     █████╗  ██╔██╗ ██║   ██║   
██╔══██╗██╔══╝  ██║     ██╔══╝  ██║╚██╗██║   ██║   
██║  ██║███████╗╚██████╗███████╗██║ ╚████║   ██║   
╚═╝  ╚═╝╚══════╝ ╚═════╝╚══════╝╚═╝  ╚═══╝   ╚═╝
*/
// RECENT: Articles
export const recentArticles = `
"articles": *[_type == "article" && !(_id in path("drafts.**"))] | order(publishedAt desc)[0..7]{
  ${articleQueryMinimal}
},
`

// RECENT: Projects
export const recentProjects = `
"projects": *[_type == "project" && !(_id in path("drafts.**"))] | order(publishedAt desc)[0..7]{
  ${projectQueryMinimal}
},
`

// RECENT: Newsletters
export const recentNewsletters = `
"newsletters": *[_type == "newsletter" && !(_id in path("drafts.**"))] | order(publishedAt desc)[0..7]{
  ${newsletterQueryMinimal}
},
`

/*
 ██████╗ ███████╗████████╗
██╔════╝ ██╔════╝╚══██╔══╝
██║  ███╗█████╗     ██║   
██║   ██║██╔══╝     ██║   
╚██████╔╝███████╗   ██║   
 ╚═════╝ ╚══════╝   ╚═╝   
*/
// All jobs listings
export const getJobs = `*[_type == "job"]`

// All team members
export const getTeam = `*[_type == "team" && !(_id in path("drafts.**"))] | order(_createdAt asc) {
  "name": teamMemberName,
  "role": teamMemberRole,
  "bio": teamMemberBio,
  "colour": teamMemberColour,
  "image": teamMemberPhoto,
}
`

// All projects
export const getProjects = `*[_type == "project" && !(_id in path("drafts.**")) | order(order asc)]{
  _type,
  _key,
  title,
  "client": projectClient,
  slug,
  projectShortService,
  "desc": projectShortDescription,
  "thumb": projectThumbnail,
  projectMainImage,
  "url": projectUrl,
  services,
  introduction,
  caseStudyBuilder,
  relatedProjects
}
`

// All newsletters
export const getNewsletters = `
*[_type == "newsletter" && !(_id in path("drafts.**"))]{
  title,
  category,
  date
}
`

export const newsletter = `
...,
"mainImage": mainImage.asset->,
"slug": slug.current,
"date": Date,
articles[]->{
  ...,
  "slug": slug.current,
},
`

// Page Builder Queries
export const builderQueries = `
_type == "showreel" => {
  _type,
  title,
  images[]{
    ...,
  }
},
_type == "spacerBlock" => {
  _type,
  gap
},
_type == "articleFeed" => {
  _type,
  isEnabled,
  perPage,
  signupLocation,
  animateOnScroll
},
_type == 'homeHeroBlock' => {
  _type,
  title,
  "subtitle": {
    "mobile": mSubtitle,
    "desktop": dSubtitle
  },
  introduction,
  image,
  reviews[]->{
    "count": reviewRating,
    "name": revieweeName,
    "content": reviewContent,
  },
  "cta": {
    ${cta}
  }
},
_type == 'pageHeroBlock' => {
  _type,
  theme,
  title,
  logo,
  fitContent,
  "subtitle": {
    "mobile": mSubtitle,
    "desktop": dSubtitle
  },
  introduction,
  image,
  reviews[]->{
    "count": reviewRating,
    "name": revieweeName,
    "content": reviewContent,
  },
  "cta": {
    ${cta}
  }
},
_type == 'contactCta' => {
  _type,
  theme,
  text,
  title,
  "cta": {
    ${cta}
  }
},
_type == 'draggableColumns' => {
    _type,
    theme,
    title,
    "columns": columns[]{
      _type,
      _key,
      "title": columnTitle,
      "text": columnText
    }
  },
  _type == 'featuredProjects' => {
    _type,
    theme,
    title,
   "body": description,
    "projects" : projects[]->{
      "title": projectClient,
      "slug": "/work/" + slug.current,
      "desc": projectShortDescription,
      "image": projectThumbnail,
    }
  },
  _type == 'careersFeed' => {
    _type,
    theme,
    title,
    description,
    "jobs" : careersList[]->{
      "title": title,
      "slug": "/careers/" + slug.current,
      "desc": shortDescription,
      "image": mainImage,
    }
  },
_type == 'imageBlock' => {
    _type,
    theme,
    "align": ver,
    fontType,
    title,
    text[]{
      ${textMeta}
    },
    image,
    "link": {
      "text": linkText,
      "internalUrl": {
        "path": *[_id == ^.linkUrl.internalLink._ref][0]._type,
        "url": "/" + *[_id == ^.linkUrl.internalLink._ref][0].slug.current
      },
      "externalUrl": linkUrl.externalUrl
    }
  },
_type == 'mediaBlock' => {
  _type,
  theme,
  bgColour,
  mediaType,
  widthType,
  video,
  mobileVideo,
  image,
  mobileImage,
  paddingX,
  noTopPadding,
  noBottomPadding
},
_type == 'video' => {
  _type,
  isYoutube,
  youtubeVideo,
  title,
  description,
  bgColour,
  textColour,
  "link": videoLink.asset->url
},
_type == 'scrollingImages' => {
  _type,
  theme,
  title,
  desc,
  "imageContentTop": imageTop.images,
  "imageContentBottom": imageBottom.images ,
  scrollDuration,
  type,
  "id": _key
},
_type == 'imageTextBlock' => {
  _type,
  theme,
  "align": ver,
  fontType,
  title,
  imagePlacement,
  text[]{
    ${textMeta}
  },
  image,
  "link": {
    "text": linkText,
    "internalUrl": {
      "path": *[_id == ^.linkUrl.internalLink._ref][0]._type,
      "url": "/" + *[_id == ^.linkUrl.internalLink._ref][0].slug.current
    },
    "externalUrl": linkUrl.externalUrl
  }
},
_type == 'textBlock' => {
    _type,
    theme,
    align,
    isSingleCol,
    showShopifyBadge,
    text
  },
  _type == 'shopifyPartner' => {
    _type,
    theme,
    flow,
    text
  },
_type == 'textColumns' => {
  _type,
  theme,
  textLeft[]{
    ${textMeta}
  },
  textRight[]{
    ${textMeta}
  },
  title,
  "cta": {
    ${cta}
  },
},
_type == 'accordion' => {
  _type,
  theme,
  accordionContent,
  optionalSideSection{
    sideTitle,
    sideDescription,
    "cta": {
      ${cta}
    }
  },
  title,
},
_type == 'heroBlock' => {
  _type,
  theme,
  image,
  noTopPadding,
  noBottomPadding
},
_type == 'projectExplorer' => {
  _type,
  theme,
  projects[]->{
    "client": projectClient,
    slug,
    projectShortService,
    "desc": projectShortDescription,
    "thumb": projectThumbnail,
    "url": projectUrl,
  },
  sideContent,
  theme
},
_type == 'calendlyBlock' => {
  _type,
  theme,
  isEnabled,
  title,
  desc,
  "cta": {
    ${cta}
  },
},
_type == 'reviews' => {
  _type,
  theme,
  title,
  description,
  "reviews": reviewsList[]->{
    "image": image,
    "name": revieweeName,
    "title": revieweeJobTitle,
    "company": revieweeClient,
    "content": reviewContent,
    "stars": reviewRating,
  },
  "cta": {
    ${cta}
  }
},
_type == 'imageGrid' => {
  _type,
  imageGridTopLeft,
  imageGridBottomLeft,  
  imageGridTopRight,
  imageGridBottomRight
},
_type == 'highlightBox' => {
  _type,
  theme,
  title,description,
  logoList[]->{
    clientName,
    clientLogo
  }
},
_type == 'stats' => {
  _type,
  "cta": {
    ${cta}
  },
  text,
  stat, 
  title
},
_type == 'faq' => {
  _type,
  "title": items.title,
  "theme": items.theme,
  "description": items.description,
  "items": items.items
},
_type == 'recentArticles' => {
  _type,
  title,
  theme,
  description
},
_type == 'teamCards' => {
  _type,
  title,
  theme,
  desc,
},
`

// Site Query
export const site = `
  "site": {
    "rootDomain": *[_type == "generalSettings"][0].siteUrl,
    "seo":  *[_type == "generalSettings"][0] {
      "title": siteTitle,
      "altTitle": siteTitleAlt,
      "description": siteDescription,
      "url": siteUrl,
      "image": siteImage,
      "logo": siteLogo,
      "language": siteLanguage,
    },
    "misc": *[_type == "miscSettings"][0] {
      headerButton
    },
    "contact": *[_type == "contactSettings"][0] {
      generalEmail,
      newBusinessEmail,
      telephoneNumber
    },
    "social": *[_type == "socialSettings"][0]{
      "twitter": twitterHandle,
      "instagram": instagramHandle,
      "linkedin": linkedInHandle,
      "facebook": facebookHandle,
      "youtube": youtubeHandle,
      "pinterest": pinterestHandle,
      "calendly": calendlyURL
    },
    "team": ${getTeam},
    "labels": {
      "contact": *[_type == "miscSettings"][0]{
        "hiring": contactSettings.hiringCTA,
        "project": contactSettings.startProjectCTA
      },
      "work": *[_type == "miscSettings"][0]{
        "local": workSettings.workItemCTA,
        "live": workSettings.workItemLiveCTA
      } 
    },
    "recents": {
      ${recentArticles}
      ${recentNewsletters}
      ${recentProjects}
    },
    "nav": *[_type == "navigation" && navId == "main-nav"][0]{
      "mainNav": items[]{
        _key,
        _type,
        text,
        hasSubNav,
        subtitle,
        subtext,
        "cta": {
          ${cta}
        },
        subNav[]->{
          "slug": slug.current,
        },
        navigationItemUrl{
          _type,
          internalLink{
             "slug": *[_id == ^._ref][0].slug.current,
             "internalUrl": "/" + *[_id == ^._ref][0].slug.current,
            }
          }
      }
    },
    "footer": *[_type == "navigation" && navId == "footer-nav"][0]{
      items[]{
        _type,
        text,
        subLinks[]{
          linkText,
          "link": {
            "internalUrl": {
              "path": *[_id == ^.internalLink._ref][0]._type,
              "url": "/" + *[_id == ^.internalLink._ref][0].slug.current
            },
            "externalUrl": {
              "url": externalUrl
            },
          },
        }
      }
    }
  }
`

// Article Query
export const articleQuery = `
_id,
_createdAt,
publishedAt,
_updatedAt,
title,
"times": {
  "numberOfCharacters": length(pt::text(articleContent)),
  "estimatedWordCount": round(length(pt::text(articleContent)) / 5),
  "estimatedReadingTime": round(length(pt::text(articleContent)) / 5 / 180)
},
author[]->{
  authorName,
  authorPhoto
},
likes,
"slug": slug.current,
seoContent,
"colour": mainImage.asset->metadata.palette {
  darkMuted,
  darkVibrant,
  dominant,
  lightMuted,
  lightVibrant,
  muted,
  vibrant
},
"image": mainImage,
excerpt,
tags[]{
  label,
  value
},
articleContent[]{
  ${textMeta}
},
relatedArticles[]{
  ${relatedArticles}
}
`

// Project Query
export const projectQuery = `
...,
_id,
title,
"slug": slug.current,
seoContent,
caseStudyBuilder[]{
  ${builderQueries}
},
`

// Newsletter Query
export const newsletterQuery = `
"title": "#" + episode + " ― " + title,
subtitle,
"slug": "/newsletters/" + slug.current,
mainImage,
`
